import {ApiError, Exception} from "./types";
import {
	BadRequestException,
	ForbiddenException,
	NotAllowedException, NotFoundException,
	OrganizationAccessException,
	TooManyRequestsException, UnauthorizedException,
	UnprocessableEntity
} from "./exceptions";


export default function handleException(exception: Exception | unknown): Array<ApiError> {


	if (!(exception instanceof Error)) {
		throw exception;
	}
	if (exception instanceof BadRequestException) {
		return [
			{
				message: "wrong_data",
				code: "wrong_data",
			},
		];
	}

	if (exception instanceof NotFoundException) {
		return [
			{
				message: "not_found",
				code: "not_found",
			},
		];
	}
	if (exception instanceof ForbiddenException) {
		const errors = exception.data.errors;
		return [
			{
				message: "access_forbidden",
				code: "access_forbidden",
				missing_permissions: errors[0]?.missing_permissions,
				eligible_users: errors[0]?.eligible_users,
				organization_name: errors[0]?.organization_name,
			},
		];
	}
	if (exception instanceof UnauthorizedException) {
		return [
			{
				message: "unauthorized",
				code: "unauthorized",
			},
		];
	}

	if (exception instanceof NotAllowedException) {
		return [
			{
				message: "not_allowed",
				code: "not_allowed",
			},
		];
	}

	if (exception instanceof OrganizationAccessException) {
		return exception.errors;
	}

	if (exception instanceof TooManyRequestsException) {
		return [
			{
				message: "too_many_requests",
				code: "too_many_requests",
			},
		];
	}

	if (exception instanceof UnprocessableEntity) {
		return exception.errors;
	}

	// if (exception instanceof CanceledError) {
	// 	return [exception];
	// }

	const error = exception as Exception;
	if (!error?.errors) {
		console.log(error);
	}

	return error?.errors
		? error.errors
		: [
			{
				message: "unknown_error",
				code: "unknown_error",
			},
		];
}
