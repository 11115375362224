import React, {FC} from 'react';
import {RouteComponentProps} from "react-router-dom";
import {AppConfig} from "../../../../services/appConfig";

const UserRemoveAccountInfoPage: FC<RouteComponentProps> = ({match}) => {
    let config = AppConfig.config;
    if(!config) return null;
    return (
        <>
            <div style={{maxWidth: '600px', margin: '0 auto', padding: '20px', fontFamily: 'Arial, sans-serif'}}>
                <h1>Usuwanie konta i danych osobowych</h1>
                <p>
                    Dążymy do zapewnienia naszym użytkownikom pełnej kontroli nad swoimi danymi osobowymi. Jeśli chcesz
                    usunąć swoje konto i powiązane z nim dane
                    osobowe, postępuj zgodnie z poniższymi instrukcjami.
                </p>

                <h2>Proces usuwania konta</h2>

                <ol>
                    <li>
                        <strong>Samodzielne usunięcie konta:</strong>
                        <p>
                            Możesz usunąć swoje konto, logując się do systemu i przechodząc do sekcji <strong>Ustawienia
                            konta</strong>. Wybierz opcję{' '}
                            <strong>Usuń konto</strong>, aby natychmiastowo usunąć swoje konto i wszystkie powiązane
                            dane.
                        </p>
                    </li>

                    <li>
                        <strong>Zgłoszenie usunięcia konta przez e-mail:</strong>
                        <p>
                            Jeśli wolisz zgłosić usunięcie konta ręcznie, skontaktuj się z naszym zespołem wsparcia,
                            wysyłając wiadomość na adres e-mail:{' '}
                            <a href={`${config.company_email}`}>{config.company_email}</a>. W tytule wiadomości wpisz
                            „Usunięcie konta”, a w treści podaj swoje imię,
                            nazwisko i adres e-mail powiązany z kontem, abyśmy mogli zweryfikować Twoją tożsamość i
                            przystąpić do procesu usunięcia danych.
                        </p>
                    </li>
                </ol>

                <h2>Informacje dodatkowe</h2>
                <p>
                    Po usunięciu konta wszystkie Twoje dane osobowe zostaną nieodwracalnie usunięte z naszego systemu. W
                    przypadku jakichkolwiek pytań lub
                    wątpliwości dotyczących procesu usunięcia konta, zachęcamy do kontaktu z nami na podany wyżej adres
                    e-mail.
                </p>

                <p>Dziękujemy, że dbasz o swoje dane osobowe.</p>
            </div>
        </>
    )
};

export default UserRemoveAccountInfoPage;
