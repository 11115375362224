import {Address} from "../services/Api/types";

var platform = window.navigator.platform.toLowerCase();
var standalone = (window.navigator as any).standalone,
    userAgent = window.navigator.userAgent.toLowerCase(),
    safari = /safari/.test(userAgent),
    ios = /iphone|ipod|ipad/.test(userAgent) || platform.includes("ios") || platform.includes("iphone") || platform.includes("ipad");

class Utils {

    static parseAddressToString(address: Address) {
        let fullName = "";
        if (address.street) {
            fullName += address.street;
            if (address.build_nr) {
                fullName += " " + address.build_nr;
            }
            if (address.city) {
                fullName += ", " + address.city;
            }
            if (address.zip_code) {
                fullName += " " + address.zip_code;
            }
        } else {
            if (address.city) {
                fullName += address.city;
                if (address.build_nr) {
                    fullName += " " + address.build_nr;
                }
                if (address.zip_code) {
                    fullName += ", " + address.zip_code;
                }
            }
        }
        return fullName;
    }

    static parseAddress(query: string) {
        let description = query;
        let descriptionSplit = description.split(",");
        let address = {
            street: '',
            build_nr: '',
            city: '',
            country: '',
            description: '',
            full: ''
        };
        if (descriptionSplit.length === 3) {
            let street = descriptionSplit[0];
            address.street = this.parseStreet(street);
            let buildNr = this.parseBuildNr(street);
            if (buildNr !== undefined) address.build_nr = buildNr;
            address.city = this.parseCity(descriptionSplit[1]);
            address.country = descriptionSplit[2];
        }
        if (descriptionSplit.length == 2) {
            address.city = this.parseCity(descriptionSplit[0]);
            address.country = descriptionSplit[1];
        }
        if (descriptionSplit.length === 1) {
            address.country = descriptionSplit[0];
        }
        address.description = description;
        address.full = description;
        return address;
    }

    static parseBuildNr(text: string) {
        let textSplit = text.split(" ");
        let build_nr = textSplit[textSplit.length - 1];
        if (!isNaN(+build_nr)) {
            return build_nr;
        }
        return undefined;
    }

    static parseStreet(text: string) {
        let textSplit = text.split(" ");
        let build_nr = textSplit[textSplit.length - 1];
        if (!isNaN(+build_nr)) {
            text = textSplit.slice(0, textSplit.length - 1).join(" ").trim();
        }
        return text;
    }

    static parseCity(text: string) {
        let textSplit = text.trimStart().split(" ");
        let zip_code = textSplit[0];
        let zip_codeSplit = zip_code.split("-");
        if (zip_codeSplit.length === 2 && !isNaN(+zip_codeSplit[0]) && !isNaN(+zip_codeSplit[1])) {
            text = textSplit.slice(1, textSplit.length).join(" ");
        }
        return text.trim();
    }

    static getQueryParams(qs: string) {
        qs = qs.split('+').join(' ');

        var params = {} as any,
            tokens,
            re = /[?&]?([^=]+)=([^&]*)/g;

        while (tokens = re.exec(qs)) {
            let key = decodeURIComponent(tokens[1]);
            params[key] = decodeURIComponent(tokens[2]);
        }

        return params;
    }

    static getUrlParams(object: any) {
        var parameters = [];
        for (var property in object) {
            if (object.hasOwnProperty(property)) {
                parameters.push(encodeURI(property + '=' + object[property]));
            }
        }

        return parameters.length > 0 ? "?" + parameters.join('&') : "";
    }

    static nl2brString(text: string) {
        return text.split('\n').map((item, key) => {
            return `${item}<br/>`
        });
    }

    static timeout(delay: number) {
        return new Promise(res => setTimeout(res, delay));
    }

    static calculateDistance(latitude1: number, longitude1: number, latitude2: number, longitude2: number) {

        const toRadian = (n: number) => (n * Math.PI) / 180

        let lat2 = latitude2
        let lon2 = longitude2
        let lat1 = latitude1
        let lon1 = longitude1

        // console.log(lat1, lon1+"==="+lat2, lon2)
        let R = 6371  // km
        let x1 = lat2 - lat1
        let dLat = toRadian(x1)
        let x2 = lon2 - lon1
        let dLon = toRadian(x2)
        let a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(toRadian(lat1)) * Math.cos(toRadian(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
        let d = R * c
        // console.log("coordinates==?",lat1, lon1, lat2, lon2)
        // console.log("distance==?",d)
        return d;
    }

    static isNative = () => {
        if (ios) {
            if (!standalone && safari) {
                // Safari
            } else if (!standalone && !safari) {
                // iOS webview
                return true;
            }
            ;
        } else {
            if (userAgent.includes('wv')) {
                // Android webview
                return true;
            } else {
                // Chrome
            }
        }
        ;
        return false;
    }

    static isIOS = () => {
        return ios;
    }
    static isAndroid = () => {
        return userAgent.includes('wv');
    }

    static isMobile = () => {
        return window.innerWidth < 768
    }

}

export default Utils;
