
let init = false;
let firstScrollToTop = false;
class IframeWidget {
    static initialize(){

        function onElementHeightChange(elm: any, callback: any){
            var lastHeight = elm.clientHeight, newHeight;
            (function run(){
                newHeight = elm.clientHeight;
                if( lastHeight != newHeight ){
                    callback(newHeight)
                }
                lastHeight = newHeight

                if( elm.onElementHeightChangeTimer )
                    clearTimeout(elm.onElementHeightChangeTimer)

                elm.onElementHeightChangeTimer = setTimeout(run, 200)
            })()
        }

        if (window.top != window.self)
        {
            if(!init) {
                init = true;
                // window.document.body.classList.add("widget");
                onElementHeightChange(document.body, function(h:any){
                    let message = {type: 'HEIGHT_CHANGED', height: h};
                    window.parent.postMessage(message,"*");
                    // console.log('Body height changed:', h)
                });

                // window.addEventListener('scroll', function(e) {
                //     console.log(e);
                // });
            }
        }
    }
    static scrollToTop(){
        if(init){
            let message = {type: 'HEIGHT_CHANGED', height: document.body.clientHeight, scroll_to_top: firstScrollToTop};
            window.parent.postMessage(message,"*");
            firstScrollToTop = true;
        }
    }
    static scrollTo(value:any){
        if(init){
            let message = {type: 'SCROLL_PARENT_TO_IFRAME_OFFSET', offset: value};
            window.parent.postMessage(message,"*");
        }
    }
    static openModal(){
        if(init){
            let message = {type: 'SIZE_STRETCH'};
            window.parent.postMessage(message,"*");
        }
    }
    static closeModal(){
        if(init){
            let message = {type: 'SIZE_DEFAULT'};
            window.parent.postMessage(message,"*");
        }
    }
}
export default IframeWidget;
