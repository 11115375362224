import React, {FC} from "react";

interface BlankLayoutProps {
}

const BlankLayout: FC<BlankLayoutProps> = (props) => {
  return (
    <>
      {/*<Helmet>*/}
      {/*  <title>{props.title}</title>*/}
      {/*</Helmet>*/}
      {props.children}
    </>
  );
};

export default BlankLayout;
