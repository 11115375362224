import React, {FC, useEffect, useState} from "react";
import MyAsyncTypeahead from "../myAsyncTypeahead";
import {TypeaheadComponentProps} from "react-bootstrap-typeahead/types/components/Typeahead";
import Utils from "../../../../../utils/Utils";
import {AddressFilter} from "../../services/types";
import {Menu, MenuItem} from "react-bootstrap-typeahead";
import PoweredByGoogle from "../../../../../images/logo/powered_by_google_on_white.png";
import {InputGroup} from "react-bootstrap";
import {api} from "../../../../../services/Api/api";
import CitySearchComponent from "./CitySearchComponent";
import {Address, Coordinates} from "../../../../../services/Api/types";
import StreetSearchComponent from "./StreetSearchComponent";
import {AppConfig} from "../../../../../services/appConfig";

interface Props {
    onChange: (address: Address, coordinates : Coordinates) => void,
    onCancel: () => void,
    address?: Address
}
const FormAddressComponent: FC<Props> = (props) => {
    const [address, setAddress] = useState(props.address ? props.address : {} as Address);
    const [loadingCoordinates, setLoadingCoordinates] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const searchAdressType = AppConfig.getSearchAddressType();
    const isSuggestCity = searchAdressType && searchAdressType == "SUGGEST_CITY";
    const setCity = (newCity: string, newPostalCode?: string) => {
        setAddress({ ...address, city: newCity, zip_code: newPostalCode ? newPostalCode : "" });
    };

    const setStreet = (newStreet: string) => {
        setAddress({ ...address, street: newStreet });
    };

    const setZipCode = (newZipCode: string) => {
        setAddress({ ...address, zip_code: newZipCode });
    };

    const setBuildNr = (newBuildNr: string) => {
        setAddress({ ...address, build_nr: newBuildNr });
    };

    const getCoordinates =  async (city: string, street: string, buildNr: string, zipCode: string) => {
        let params = {
            city: city,
            street: street,
            buildNr: buildNr,
            zipCode: zipCode
        }
        const coordinatesResponse =  await api.getCoordinates(params);
        return coordinatesResponse;
    }


    let saveAddress = async () => {
        setLoadingCoordinates(true);
        let coordinates = await getCoordinates(address.city, address.street, address.build_nr, address.zip_code);
        if(coordinates === undefined){
            setErrorMessage("Nie udało się znaleźć adresu")
        }else{
            props.onChange(address, coordinates);
        }
        setLoadingCoordinates(false);
    }


    return (
        <div className={"container1 mt-2 form-address"}>
            {/*{Utils.parseAddressToString(address)}*/}
            {errorMessage && (
                <div className="alert alert-danger">Nie udało się znaleźć adresu</div>
            )}
            {/*FUll address: {address.city}, {address.street} {address.build_nr}*/}
            <div className={"row gx-2"}>
                {isSuggestCity ? (
                    <>
                        <div className={"col-12 col-lg-12"}>
                            <div className="mb-2">
                                <CitySearchComponent placeholder={"Miasto"} onBlur={setCity} value={address.city} zipCode={address.zip_code} onChange={(value, postalCode) => {
                                    setCity(value, postalCode)
                                }} />
                            </div>
                        </div>
                        <div className={"col-12 col-lg-12"}>
                            <div className="mb-2">
                                <StreetSearchComponent city={address.city} placeholder={"Ulica"} onBlur={setStreet} value={address.street} onChange={(value) => setStreet(value)} />
                            </div>
                        </div>
                        <div className={"col-6  col-lg-6"}>
                            <div className="mb-2">
                                <input className={"form-control"} name="build_nr" placeholder="Numer" value={address.build_nr} onChange={(e) => setBuildNr(e.target.value)} />
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className={"col-12 col-lg-8"}>
                            <div className="mb-2">
                                <input className={"form-control"} name="street" placeholder="Nazwa ulicy" value={address.street} onChange={(e) => setStreet(e.target.value)}/>
                            </div>
                        </div>
                        <div className={"col-6 col-lg-4"}>
                            <div className="mb-2">
                                <input className={"form-control"} name="build_nr" placeholder="Numer" value={address.build_nr} onChange={(e) => setBuildNr(e.target.value)} />
                            </div>
                        </div>
                        <div className={"col-6  col-lg-6"}>
                            <div className="mb-2">
                                <input className={"form-control"} name="city" placeholder="Miasto" value={address.city} onChange={(e) => setCity(e.target.value)} />
                            </div>
                        </div>
                    </>
                )}
                <div className={"col-6 col-lg-3"}>
                    <button className={"btn btn-outline-primary w-100"} type="button" onClick={() => props.onCancel()}>Anuluj</button>
                </div>
                <div className={"col-6 col-lg-3"}>
                    {loadingCoordinates ? (
                        <button disabled={true} className={"btn btn-primary w-100"} type="button">Ładuje ...</button>
                    ) : (
                        <button className={"btn btn-primary w-100"} type="button" onClick={() => saveAddress()}>Zapisz</button>
                    )}
                </div>
            </div>
        </div>
    );
}
export default FormAddressComponent;
