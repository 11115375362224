import React, {FC, useEffect, useState} from "react";
import {Button, Modal} from "react-bootstrap";
import {Coordinates} from "../../../../services/Api/types";
import {ReactComponent as MarkerSVG} from "../../../../images/svg/marker.svg";
import {LoadingContainer} from "../../../../components/Common/Loading/Loading";


interface Props {
    coordinates?: Coordinates,
    onChangeCoordinates: (coordinates: Coordinates) => void;
}

const LocationModalComponnent: FC<Props> = (props) => {
    const [show, setShow] = useState(props.coordinates === undefined);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    useEffect(() => {
        if (props.coordinates) {
            setLoading(false);
            setShow(false);
        }else{
            setLoading(false)
        }
    }, [props.coordinates]);

    const geolocation = () => {
        if ("geolocation" in navigator) {
        } else {
            alert("Geolokalizacja niedostępna")
            return;
        }
        setLoading(true);
        setError("");
        navigator.geolocation.getCurrentPosition(function (position) {
            props.onChangeCoordinates({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
            } as Coordinates)
            setLoading(false);
            setError("");
        }, (err) => {
            console.log(err);
            if(err && err.message === "User denied Geolocation"){
                setError("Użytkownik zablokował dostęp do geolokalizacji");
            }else{
                setError("Nie udało się pobrać lokalizacji")
            }
            // alert("Geolokalizacja niedostępna: " + err.message)
            // alert("Geolokalizacja niedostępna")
            setLoading(false);
        });
    }

    const onShow = () => {
    }
    const onHide = () => {
    }
    return (
        <>
            <Modal dialogClassName="modal-full1 modal-dialog-centered" show={show} onHide={onHide} onShow={onShow}>
                <Modal.Body>
                    <div className={""} style={{minHeight: 4}}>
                        <div className={"text-center mb-4"}>
                            <MarkerSVG style={{height: 50, width: 50}} className={"mb-4 mt-4"}/>
                            <h5 style={{fontWeight: 300}}>
                                Udostępnić T-pizza Twoją lokalizację??
                            </h5>
                        </div>

                        {error && (
                            <p className={"text-danger text-center"}>
                                {error}
                            </p>
                        )}
                        {loading ? (
                            <>
                            <LoadingContainer value={"Trwa pobieranie lokalizacji ... "} />
                                <Button variant="default" className={"w-100 mb-2"} onClick={() => setShow(false)}>
                                    Anuluj
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button variant="primary" className={"w-100 mb-2"} onClick={geolocation}>
                                    Zezwalaj
                                </Button>
                                <Button variant="default" className={"w-100 mb-2"} onClick={() => setShow(false)}>
                                    Anuluj
                                </Button>
                            </>
                        )}
                    </div>

                </Modal.Body>
            </Modal>
        </>
    )
};

export default LocationModalComponnent;
