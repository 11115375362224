import React, {useEffect, useState} from 'react';
import {BrowserRouter} from "react-router-dom";
import Routes from "./Routes";
import ScrollToTop from "./components/Common/ScrollToTop/ScrollToTop";
import {Helmet, HelmetProvider} from 'react-helmet-async';
import {ConfigApi} from "./services/Api/types";
import {api} from "./services/Api/api";
import {AppConfig} from "./services/appConfig";
import {LoadingContainer} from "./components/Common/Loading/Loading";
import IframeWidget from "./utils/IframeWidget";
import CustomStyle from "./components/layouts/CustomStyle";
import Analytics from "./utils/Analytics";

const isWebView = () => {
    if (typeof window === undefined) {
        return false
    }
    ;

    let navigator: any = window.navigator;

    const standalone = navigator.standalone;
    const userAgent = navigator.userAgent.toLowerCase();
    const safari = /safari/.test(userAgent);
    const ios = /iphone|ipod|ipad/.test(userAgent);

    if (userAgent.includes("mobileapp")) return true;
    return ios ? !standalone && !safari : userAgent.includes('wv');
}

const getCfgBaseOnDomain = (domain: string) => {
    if (domain.includes("zamowienia-test.tpizza.pl")) {
        return "tpizza-test"
    }
    if (domain.includes("zamowienia-demo.tpizza.pl")) {
        return "tpizza-demo"
    }
    if (domain.includes("zamowienia.tpizza.pl") || domain.includes("zamowienia2.tpizza.pl")) {
        return "tpizza"
    }
    if (domain.includes("tpizza") && domain.includes(".local")) {
        // return "tpizzalocal"
        return "tpizza"
    }
    return undefined;
}
//
// // Example usage
// if (isWebView()) {
//     console.log("This is running inside a WebView.");
// } else {
//     console.log("This is running in a regular browser.");
// }
function App() {
    const [loading, setLoading] = useState(true);
    const [config, setConfig] = useState<ConfigApi | undefined>(undefined);
    const [ignoreMock, setIgnoreMock] = useState<boolean>(false);
    const initConfig = async () => {
        let pathname = window.location.pathname;
        const searchParams = new URLSearchParams(window.location.search);
        const goloyalty = searchParams.get('goloyalty');
        if (goloyalty) {
            AppConfig.setGoLoyalty(true);
        }
        if (pathname.includes("widget_native")) {
            AppConfig.setWidget(true);
            AppConfig.setPrefix("widget_native");
            IframeWidget.initialize();
        } else if (pathname.includes("widget")) {
            AppConfig.setWidget(true);
            AppConfig.setPrefix("widget");
            IframeWidget.initialize();
        }
        let configName = "";
        if (pathname.includes("portal/eater")) {
            if (AppConfig.isWidget()) {
                configName = pathname.split("/")[4];
            } else {
                configName = pathname.split("/")[3];
            }
            AppConfig.setBaseName("/portal/eater/" + configName);
        } else {
            const currentDomain = window.location.hostname;
            let currentDomainCfg = getCfgBaseOnDomain(currentDomain);
            if (currentDomainCfg) configName = currentDomainCfg;
        }
        if (configName && !configName.includes("widget")) {
            let configResponse = await api.getConfig(configName);
            AppConfig.initConfig(configName, configResponse)
            AppConfig.initQueryParams();
            setConfig(configResponse);
            if (configResponse?.module_stores.google_analytics_id) {
                Analytics.addGA(configResponse?.module_stores.google_analytics_id);
            }
        } else {
            AppConfig.initQueryParams();
        }


        setLoading(false)
    }
    useEffect(() => {
        initConfig()
    }, [])
    if (loading) {
        return (
            <LoadingContainer/>
        );
    }
    let color = AppConfig.getColor();
    return (
        <HelmetProvider>
            <div className={`App${AppConfig.isWidget() ? " widget" : ""}`}>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>{config?.title}</title>
                    <meta name="viewport"
                          content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=no"/>
                    <meta name="apple-mobile-web-app-capable" content="yes"/>
                    {config?.description && <meta name="description" content={config?.description}/>}
                    {config?.ico && <link rel="icon" type="image/x-icon" href={config.ico}/>}
                    {config ? (<>
                        {/*<link rel="apple-touch-icon" href="assets/icons/ic-tapporder-512x512.png" />*/}
                        {/*<link rel="apple-touch-icon" sizes="152x152" href="assets/icons/ic-tapporder-152x152.png" />*/}
                        {/*<link rel="apple-touch-icon" sizes="180x180" href="assets/icons/ic-tapporder-192x192.png" />*/}
                        {/*<link rel="apple-touch-icon" sizes="167x167" href="assets/icons/ic-tapporder-192x192.png" />*/}
                    </>) : ""}
                </Helmet>
                {color && (
                    <CustomStyle color={color}/>
                )}
                <BrowserRouter>
                    <ScrollToTop/>
                    <Routes/>
                </BrowserRouter>
            </div>
        </HelmetProvider>
    );
}

export default App;
