import React, {FC, useEffect, useState} from "react";
import {UserContext} from "../../../../services/UserContext";
import handleException from "../../../../services/Api/handleException";
import {ApiError} from "../../../../services/Api/types";
import {useLocation} from "react-router-dom";
import {LoadingContainer} from "../../../../components/Common/Loading/Loading";


interface Props {
    onSuccess: () => void
}

const Activation: FC<Props> = (props) => {
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState<ApiError[]>([]);
    const location = useLocation();
    const userContext = React.useContext(UserContext);
    const queryParams = new URLSearchParams(location.search);
    const getQueryParam = (param: string): string | undefined => {
        const value = queryParams.get(param);
        return value !== null ? value : undefined;
    };

    const code = getQueryParam('code');
    const email = getQueryParam('email');
    const isValid = () => {
        return code && email;
    }

    useEffect(() => {
        if(isValid()){
            activation();
        }
    }, []);

    if(!isValid()){
        return (
            <>
                Niepoprawny E-mail albo Kod, wpisz jeszcze raz e-mail na jaki chcesz wygenerować link aktywacyjny.
            </>
        )
    }
    const activation = async () => {
        if(!email || !code) return;
        setLoading(true);
        try {
            await userContext.activation(email, code);
            setLoading(false);
        } catch (err) {
            const exceptionErrors = handleException(err);
            setErrors(exceptionErrors);
            setLoading(false);
        }
    }
    return (
        <>
            {loading ? (
                <LoadingContainer></LoadingContainer>
            ) : (
                <>
                    {(errors && errors.length > 0) ? (
                        <>
                            <h3>Niepoprawna aktywacja konta</h3>
                            <p>
                                Dziękujemy za próbę aktywacji Twojego konta. Niestety, coś poszło nie tak, i proces aktywacyjny nie został zakończony pomyślnie.
                            </p>
                            <p>
                                <button className={"btn btn-primary"}>Wyślij link jeszcze raz</button>
                            </p>
                        </>
                    ) : (
                        <>
                            <h3>Konto zostało aktywowane</h3>
                            <p>
                                Cieszymy się poinformować, że Twoje konto zostało pomyślnie aktywowane!
                            </p>
                            <p>
                                <button className={"btn btn-primary"} onClick={() => props.onSuccess()}>Kliknij tutaj i przejdz do aplikacji</button>
                            </p>
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default Activation;
