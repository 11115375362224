import React, {FC} from 'react';
import {Route, RouteComponentProps, Switch} from "react-router-dom";
import UserSettingsIndexPage from "./pages/Index";
import UserSettingsDeletePage from "./pages/Delete";

const UserSettingsPage: FC<RouteComponentProps> = ({match}) => {
    let matchUrl = match.url;
    return (
        <Switch>
            <Route path={matchUrl + "/delete"} component={UserSettingsDeletePage}/>
            <Route path={matchUrl + ""} component={UserSettingsIndexPage}/>
        </Switch>
    )
};

export default UserSettingsPage;
