import {CityApi, ConfigApi, Coordinates, ProfileApi} from "../types";
import {delay} from "go-core";

export type Props = {
  profiles: ProfileApi[];
  coordinates: Coordinates | undefined,
  config: ConfigApi | undefined,
  cities: CityApi[]
}

function getRandomInt() {
  return 100 + Math.floor(Math.random() * Math.floor(9999));
}

const data : Props = {
  profiles: [],
  cities: [],
  coordinates: undefined,
  config: undefined
}
const delayTime =  0;
class MockApi {
  static async init(profiles: ProfileApi[], coordinates: any, config:ConfigApi, cities:CityApi[]){
    data.profiles = profiles;
    data.coordinates = coordinates;
    data.config = config;
    data.cities = cities;
  }
  static async getProfiles(){
    await delay(delayTime);
    return data.profiles;
  }
  static async getCoordinates(){
    await delay(delayTime);
    return data.coordinates;
  }
  static async getConfig(){
      await delay(delayTime);
      return data.config;
  }

  static async getCities(){
    await delay(delayTime);
    return data.cities;
  }
}

export { MockApi }