import React, {FC, useState} from "react";
import MyAsyncTypeahead from "../myAsyncTypeahead";
import {TypeaheadComponentProps} from "react-bootstrap-typeahead/types/components/Typeahead";
import Utils from "../../../../../utils/Utils";
import {AddressFilter} from "../../services/types";
import {Menu, MenuItem} from "react-bootstrap-typeahead";
import PoweredByGoogle from "../../../../../images/logo/powered_by_google_on_white.png";
import {InputGroup} from "react-bootstrap";
import {api} from "../../../../../services/Api/api";
import BaseSearchComponent from "./BaseSearchComponent";

interface Props {
    onChange: (value:string) => void,
    onBlur: (value: string) => void,
    value: string,
    placeholder: string,
    city: string
}
const StreetSearchComponent: FC<Props> = (props) => {
    const fetch = async (query: string) => {
        let response = await api.getStreets(props.city, query)
        return response.map((c:any) => {
            return {
                description:c
            }
        })
    }
    const onChange = (value: any) => {
        props.onChange(value.description);
    }
    const onBlur = (value: any) => {
        props.onBlur(value.description);
    }

    return (
        <BaseSearchComponent fetch={fetch} value={props.value} onBlur={onBlur} onChange={onChange} placeholder={props.placeholder} />
   );
}
export default StreetSearchComponent;
