import React, {FC, useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import {UserContext} from "../../../../services/UserContext";
import {ApiError, ConfigApi} from "../../../../services/Api/types";
import handleException from "../../../../services/Api/handleException";
import ErrorFormAlertView from "../../../../components/Common/ErrorFormAlertView";
import {api} from "../../../../services/Api/api";


interface Props {
    show: boolean;
    config: ConfigApi;
    onHide: () => void
}

const VerifiedModal: FC<Props> = (props) => {
    const [loading, setLoading] = useState(false);
    const [loadingRefresh, setLoadingRefresh] = useState(false);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [errors, setErrors] = useState<ApiError[]>([]);
    const userContext = React.useContext(UserContext);

    const onShow = () => {}
    const send = async () => {
        setLoading(true);
        try {
            await userContext.sendVerificationLink()
            setLoading(false);
        } catch (err) {
            const exceptionErrors = handleException(err);
            setErrors(exceptionErrors);
            setLoading(false);
        }
        setLoading(false)
    }
    const refresh = async () => {
        if (isRefreshing) {
            return;
        }
        setIsRefreshing(true);
        setLoadingRefresh(true)
        let response = await userContext.getMe();
        if(response && response.verified){
             await userContext.initUser();
        }
        setIsRefreshing(false);
        setLoadingRefresh(false);
    }
    const logout = async () => {
        await userContext.logout()
    }

    useEffect(() => {
        refresh();
        const intervalId = setInterval(refresh, 3000);
        return () => clearInterval(intervalId);
    }, []);

    return (
        <>
            <Modal dialogClassName="modal-full modal-login" show={props.show} onHide={props.onHide} onShow={onShow}>
                <div className="">
                    <Modal.Header>
                        Weryfikacja konta wymagana
                        {/*<button onClick={props.onHide} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>*/}
                    </Modal.Header>
                </div>
                <Modal.Body>
                    <div className={"login-box"}>
                        {props.config.logo ? (
                            <div className={"login-box-logo"}>
                                <img src={props.config.logo} />
                            </div>
                        ) : "Logo"}
                        <p>
                            Twoje konto nie zostało jeszcze zweryfikowane. Sprawdź swoją skrzynkę e-mail, w tym folder ze spamem, aby znaleźć wiadomość weryfikacyjną dla adresu: <strong>{userContext.user?.email}</strong>. Jeśli nie otrzymałeś/aś wiadomości, spróbuj ponownie wysłać link weryfikacyjny.
                        </p>
                        <ErrorFormAlertView errors={errors} />
                        <button className={"btn-spinner btn-block btn-primary btn btn-submit" + (loadingRefresh ? ' loading' : '')} onClick={()=> refresh()}>
                            <span className="left spinner-border spinner-border-sm"></span>
                            Odśwież
                        </button>
                        <div className="horizontal-row"><div className="horizontal-text">lub</div></div>
                        <button className={"btn-spinner btn-block btn-primary btn btn-submit" + (loading ? ' loading' : '')} onClick={()=> send()}>
                            <span className="left spinner-border spinner-border-sm"></span>
                            Wyślij link weryfikacyjny
                        </button>
                        <button className={"btn btn-default"} onClick={() => logout()}>
                            Wyloguj się
                        </button>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    )
};

export default VerifiedModal;
