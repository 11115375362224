import React, {FC} from "react";
import {Spinner} from "react-bootstrap";

interface LoadingProps {
  value?: string;
}

const Loading: FC<LoadingProps> = ({value}) => {

  let text =  (
      <p>Trwa ładowanie ...</p>
  );

  return(
      <div className="loading-app text-center">
          <Spinner animation="border" />
        {text}
      </div>
  )
};



const LoadingContainer: FC<LoadingProps> = ({value}) => {

    return(
        <div className="loading-container">
            <Loading />
        </div>
    )
};

export {Loading, LoadingContainer}