import React, {FC} from "react";
import {api} from "../../../../../services/Api/api";
import BaseSearchComponent from "./BaseSearchComponent";
import {AppConfig} from "../../../../../services/appConfig";


interface Props {
    onChange: (value:string, postalCode: string) => void,
    onBlur: (value: string, postalCode: string) => void,
    value: string,
    zipCode: string,
    placeholder: string
}
const CitySearchComponent: FC<Props> = (props) => {
    const fetch = async (query: string) => {
        let searchCitiesType = AppConfig.getSearchCitiesType();
        let params = {} as any
        if(searchCitiesType == "stores"){
            params.group_domains = AppConfig.getDomains().join(",");
        }
        let response = await api.getCities(query, params)
        return response.map((c:any) => {
            let isOnlyCity = !c.postalCode || response.some(x => x.cityName == c.cityName);
            if(isOnlyCity){
                return {
                    description:c.cityName,
                    postalCode: c.postalCode
                }
            }
            return {
                description:c.cityName,
                postalCode: c.postalCode,
                renderText: () => {
                    return (
                        <>
                            {c.cityName}
                            {c.postalCode && (
                                <small>
                                    , {c.postalCode}
                                </small>
                            )}
                        </>
                    )
                }
            }
        })
    }
    const onChange = (value: any) => {
        props.onChange(value.description, value.postalCode);
    }
    const onBlur = (value: any) => {
        props.onBlur(value.description, value.postalCode);
    }

    let newValue = {description: props.value ? props.value : "", postalCode: props.zipCode};
    return (
        <BaseSearchComponent fetch={fetch} value={newValue} onBlur={onBlur} onChange={onChange} placeholder={props.placeholder} />
    );
}
export default CitySearchComponent;