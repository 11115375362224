import React, {FC, ReactElement, useEffect} from "react";
import {ConfigModuleStoreApi, Coordinates, ProfileApi} from "../../../../services/Api/types";
import {GoogleMap, InfoWindow, Marker} from '@react-google-maps/api';
import EaterItem from "../Item";
import {StoreFilter} from "../services/types";
import ControlsComponnent from "../Controls/controls";

interface Props {
    profiles: ProfileApi[] | undefined,
    coordinates: Coordinates | undefined,
    openStore: (profile: ProfileApi) => void,
    showProfile: string | undefined,
    config?: ConfigModuleStoreApi,
    searchView?: ReactElement,
    nearestProfile?: ProfileApi,
    filter: StoreFilter,
    onChangeOrderType: (type: string) => void,
    onChangeViewType: (type: string) => void,
    viewType: string
}

const containerStyle = {
    // width: '400px',
    // height: '400px'
};

let defaultCenter = {
    lat: 51.9194,
    lng: 19.1451
};
let defaultZoom = 7;
const apiKey = "AIzaSyCvk6de_r1U3oKMbWOY1DztMTWMcxeKMaE";

const EaterMap: FC<Props> = (props) => {
    const [selectedProfile, setSelectedProfile] = React.useState<ProfileApi>()
    const [showMe, setShowMe] = React.useState<boolean>()
    const [center, setCenter] = React.useState<any>(defaultCenter);
    const [zoom, setZoom] = React.useState(defaultZoom);

    const selectProfile = (profile?: ProfileApi) => {
        setSelectedProfile(profile)
    }


    useEffect(() => {
        // if (selectedProfile === undefined) {
        setSelectedProfile(props.nearestProfile);
        // }
    }, [props.nearestProfile]);

    useEffect(() => {
        if (props.showProfile && props.profiles) {
            const showProfile = props.profiles.find(profile => profile.domain === props.showProfile);
            if (showProfile?.coordinates) {
                setCenter({lat: showProfile.coordinates.latitude, lng: showProfile.coordinates.longitude});
                setZoom(11);
            }
        } else if (props.coordinates) {
            setCenter({lat: props.coordinates.latitude, lng: props.coordinates.longitude});
            setZoom(11);
        }
    }, [props.showProfile, props.coordinates, props.profiles]);

    if (props.profiles === undefined) {
        return null;
    }
    let profiles = props.profiles.slice(0, 100);
    // let center = undefined;
    // let zoom = defaultZoom;
    // if (props.showProfile !== undefined) {
    //     let showProfile = profiles.filter(x => x.domain === props.showProfile)[0];
    //     if (showProfile !== undefined && showProfile.coordinates !== undefined) {
    //         center = {
    //             lat: showProfile.coordinates.latitude,
    //             lng: showProfile.coordinates.longitude,
    //         }
    //         zoom = 11;
    //     }
    // }
    // if (center === undefined) {
    //     if (props.coordinates !== undefined) {
    //         center = {
    //             lat: props.coordinates.latitude,
    //             lng: props.coordinates.longitude,
    //         }
    //         zoom = 11;
    //     } else {
    //         center = defaultCenter
    //     }
    // }

    let currentLocationIcon = undefined as string | undefined;
    let markerIcon = undefined as string | undefined;
    let selectedMarkerIcon = undefined as string | undefined;
    if (props.config && props.config.map) {
        if (props.config.map.icon_current_location) {
            currentLocationIcon = props.config.map.icon_current_location.includes("http") ? props.config.map.icon_current_location : require(`../../../../images/svg/${props.config.map.icon_current_location}/current_marker.svg`).default;
        }
        if (props.config.map.icon_marker) {
            markerIcon = props.config.map.icon_current_location.includes("http") ? props.config.map.icon_current_location : require(`../../../../images/svg/${props.config.map.icon_marker}/marker.svg`).default;
        }
        if (props.config.map.icon_marker) {
            selectedMarkerIcon = props.config.map.icon_current_location.includes("http") ? props.config.map.icon_current_location : require(`../../../../images/svg/${props.config.map.icon_marker}/selected_marker.svg`).default;
        }
    }

    const defaultIcon = {
        url: markerIcon,
        scaledSize: new google.maps.Size(35, 35), // Default marker size
    } as google.maps.Icon;

    const selectedIcon = {
        url: selectedMarkerIcon,
        scaledSize: new google.maps.Size(50, 50), // Bigger marker size for selected profile
    } as google.maps.Icon;
    const mapOptions = {
        mapTypeControl: false, // Hide Map Type Control
        fullscreenControl: false, // Hide Fullscreen Control
        streetViewControl: false, // Hide Street View Control
    };

    return (
        <div className="eater-map">
            {/*<LoadScript*/}
            {/*    googleMapsApiKey={apiKey}*/}
            {/*>*/}
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={zoom}
                options={mapOptions}
                // onLoad={onLoad}
                // onUnmount={onUnmount}
            >
                <div style={{
                    position: 'absolute',
                    bottom: '0px',
                    left: '0px',
                    right: '0px',
                    zIndex: 1000
                }}>
                    <ControlsComponnent onChangeOrderType={props.onChangeOrderType}
                                        onChangeViewType={props.onChangeViewType} filter={props.filter}
                                        viewType={props.viewType}/>
                    {selectedProfile && (
                        <div className={"map-item"}>
                            <EaterItem openStore={props.openStore} profile={selectedProfile} showUnavailable={true}/>
                        </div>
                    )}
                </div>
                {props.coordinates !== undefined && (
                    <Marker
                        icon={currentLocationIcon ? {
                            url: currentLocationIcon,
                        } : undefined}
                        position={{
                            lat: props.coordinates.latitude,
                            lng: props.coordinates.longitude
                        }}
                        onClick={() => setShowMe(true)}
                    >
                        {showMe ? (
                            <InfoWindow
                                onCloseClick={() => setShowMe(false)}
                            >
                                <div>Tutaj jesteś</div>
                            </InfoWindow>
                        ) : null}
                    </Marker>
                )}

                {profiles.map(profile => {
                    if (profile.coordinates === undefined) return undefined;
                    return (
                        <Marker key={profile.store_url}
                                position={{
                                    lat: profile.coordinates.latitude,
                                    lng: profile.coordinates.longitude
                                }}
                                icon={selectedProfile && selectedProfile.domain === profile.domain ? selectedIcon : defaultIcon}
                            // icon={markerIcon ? {
                            //     url: markerIcon,
                            // } : undefined}
                                onClick={() => selectProfile(profile)}
                        >
                            {/*{selectedProfile && selectedProfile.domain === profile.domain ? (*/}
                            {/*    <InfoWindow*/}
                            {/*        position={{*/}
                            {/*            lat: profile.coordinates.latitude,*/}
                            {/*            lng: profile.coordinates.longitude*/}
                            {/*        }}*/}
                            {/*        onCloseClick={() => selectProfile(undefined)}*/}
                            {/*    >*/}
                            {/*        <EaterItem openStore={props.openStore} profile={profile}/>*/}
                            {/*    </InfoWindow>*/}
                            {/*) : null}*/}
                        </Marker>
                    )
                })}
                <></>
            </GoogleMap>
            {/*</LoadScript>*/}
        </div>
    );
};

export default EaterMap;
