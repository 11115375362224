import React, {useEffect, useState} from 'react';
import {Route, RouteComponentProps, Switch} from "react-router-dom";
import BlankLayout from "../../components/layouts/BlankLayout";
import DashboardEaterPage from "./pages";
import NavbarDrawer from "./components/Navbar";
import {AppConfig} from "../../services/appConfig";
import {UserContext, UserService} from "../../services/UserContext";
import {LoginApi} from "../../services/Api/types";
import {LoadingContainer} from "../../components/Common/Loading/Loading";
import RegisterActivation from "./pages/RegisterActivation";
import VerifiedModal from "./components/User/verifiedModal";
import ResetPasswordPage from "./pages/ChangePassword";
import UserSettingsPage from "../User/pages/Settings";
import UserRemoveAccountInfoPage from "../User/pages/RemoveAccountInfo";

interface MatchParams {
    configId: string;
}

const AppEaterPage: React.FC<RouteComponentProps> = ({match}) => {
    const [user, setUser] = useState<LoginApi | undefined>(undefined);
    const [modalLogin, setModalLogin] = useState(true)
    const [modalVerification, setModalVerification] = useState(true)
    const [loading, setLoading] = useState(true);
    let config = AppConfig.config;
    let matchUrl = match.url;
    matchUrl = matchUrl.endsWith("/") ? matchUrl.slice(0, -1) : matchUrl;

    const login = (loginApi: LoginApi) => {
        setUser(loginApi);
    };

    const logout = () => {
        setUser(undefined);
    };
    const value = new UserService(login, logout, config?.slug, user, config?.module_crm);

    const initUser = async () => {
        if (!value.isEnabled()) {
            setLoading(false);
            return;
        }
        try {
            await value.initUser();
            setLoading(false);
        } catch (err) {
            console.log(err);
        }
    }
    useEffect(() => {
        initUser();
    }, [])

    if (loading) {
        return (
            <LoadingContainer/>
        );
    }
    return (
        <BlankLayout>
            <UserContext.Provider value={value}>
                {/*{(Utils.isNative() && config && value.isEnabled() && modalLogin && !value.user) && (*/}
                {/*    <>*/}
                {/*        <LoginModal config={config} show={true} onHide={() => setModalLogin(false)}/>*/}
                {/*    </>*/}
                {/*)}*/}
                {(config && value.isEnabled() && modalVerification && value.user && !value.user.verified) && (
                    <>
                        <VerifiedModal config={config} show={true} onHide={() => {
                            // setModalVerification(false)
                        }}/>
                    </>
                )}
                <div className={`page`}>
                    <NavbarDrawer config={config}/>
                    <Switch>
                        <Route path={matchUrl + "/login/activation"} component={RegisterActivation}/>
                        <Route path={matchUrl + "/login/reset-password"} component={ResetPasswordPage}/>
                        <Route path={matchUrl + "/user/settings"} component={UserSettingsPage}/>
                        <Route path={matchUrl + "/user/remove_account_info"} component={UserRemoveAccountInfoPage}/>
                        <Route path={matchUrl + ""} component={DashboardEaterPage}/>
                    </Switch>
                </div>
            </UserContext.Provider>
        </BlankLayout>
    );
}

export default AppEaterPage;