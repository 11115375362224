import {FC, ReactNode, Ref} from "react";
import {TypeaheadComponentProps} from "react-bootstrap-typeahead/types/components/Typeahead";
import {AsyncTypeahead} from "react-bootstrap-typeahead";

interface Props extends TypeaheadComponentProps{
    delay?: number;
    emptyLabel?: ReactNode;
    isLoading: boolean;
    onSearch: (query: string) => void;
    promptText?: ReactNode;
    searchText?: ReactNode;
    useCache?: boolean;
    placeholder?: string,
    ref?: Ref<never>;
}
const AsyncTypeahead2: FC<Props> = (props) => {
    return <AsyncTypeahead {...props} />
}
export default AsyncTypeahead2;