import React, {FC, useState} from 'react';
import {Link, RouteComponentProps, useHistory} from "react-router-dom";
import {ReactComponent as BackSVG} from "../../../../../../images/svg/back.svg";
import ErrorFormAlertView from "../../../../../../components/Common/ErrorFormAlertView";
import ErrorFormHelper from "../../../../../../utils/ErrorFormHelper";
import ErrorFormView from "../../../../../../components/Common/ErrorFormView";
import {UserContext} from "../../../../../../services/UserContext";
import handleException from "../../../../../../services/Api/handleException";
import {ApiError} from "../../../../../../services/Api/types";

const UserSettingsDeletePage: FC<RouteComponentProps> = (props) => {
    let history = useHistory();
    const [password, setPassword] = useState("");
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const userContext = React.useContext(UserContext);
    const [errors, setErrors] = useState<ApiError[]>([]);
    const deleteAccount = async () => {
        setLoading(true);
        try {
            let response = await userContext.delete(password)
            await userContext.logout();
            history.push('/');
            setLoading(false);
            setSuccess(true);
            // props.onSuccess()
        } catch (err) {
            const exceptionErrors = handleException(err);
            console.log(exceptionErrors)
            setErrors([{
                message: "Nie udało się usunąć konto, hasło niepoprawne."
            }]);
            // setErrors(exceptionErrors);
            setLoading(false);
        }
    }

    return (
        <>
            <Link to={"/user/settings"} className={"navbar-back"}>
                <BackSVG/> Wróć
            </Link>

            <div className={"container mt-2"}>
                <p>
                    Czy na pewno chcesz usunąć konto? Twoje dane zostaną bezpowrotnie usunięte.
                </p>
                <div>
                    <ErrorFormAlertView errors={errors}/>
                    <div className="form-group">
                        <input type="password" onChange={(e) => setPassword(e.target.value)} value={password}
                               className={"form-control" + ErrorFormHelper(errors, "password")} placeholder="Twoje haslo"
                               aria-label="Recipient's username" aria-describedby="cart-password"/>
                        <label htmlFor="cart-username">Twoje haslo</label>
                        <ErrorFormView errors={errors} field="password"/>
                    </div>
                </div>
                <button
                    className={"btn-spinner btn-block btn-primary btn w-100 btn-submit" + (loading ? ' loading' : '')}
                    onClick={deleteAccount}>
                    <span className="left spinner-border spinner-border-sm"></span>
                    Usuń konto
                </button>
            </div>
        </>
    )
};

export default UserSettingsDeletePage;
