import { ApiError, Exception } from "./types";

export class NotFoundException extends Error implements Exception {
	constructor() {
		super("Not found.");
	}
}

export class NotAllowedException extends Error implements Exception {
	constructor() {
		super("Not allowed method");
	}
}

export class ForbiddenException extends Error implements Exception {
	public readonly data: any;

	constructor(data: any) {
		super("Action Forbidden");
		this.data = data;
	}
}

export class UnauthorizedException extends Error implements Exception {
	public readonly data: any;

	constructor(data: any) {
		super("Unauthorized");
		this.data = data;
	}
}

export class UnprocessableEntity extends Error implements Exception {
	public readonly errors: ApiError[];

	constructor({ errors }: { errors: ApiError[] }) {
		super("UnprocessableEntity");
		this.errors = errors;
	}
}

export class BadRequestException extends Error implements Exception {
	public readonly data: any;

	constructor(data: any) {
		super("BadRequest");
		this.data = data;
	}
}

export class UnknownException extends Error implements Exception {
	public readonly status: number;
	public readonly url: string;

	constructor(error: { status: number; url?: string }) {
		super("Unknown");
		this.status = error.status;
		this.url = error.url ? error.url : "";
	}
}

export class OrganizationAccessException extends Error implements Exception {
	public readonly errors: ApiError[];

	constructor({ errors }: { errors: ApiError[] }) {
		super("OrganizationAccessExceeded");
		this.errors = errors;
	}
}

export class TooManyRequestsException extends Error implements Exception {
	constructor() {
		super("TooManyRequestsException");
	}
}
