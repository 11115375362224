import React, {FC, useState} from "react";
import ErrorFormAlertView from "../../../../components/Common/ErrorFormAlertView";
import ErrorFormView from "../../../../components/Common/ErrorFormView";
import ErrorFormHelper from "../../../../utils/ErrorFormHelper";
import {UserContext} from "../../../../services/UserContext";
import handleException from "../../../../services/Api/handleException";
import {ApiError} from "../../../../services/Api/types";
import {useLocation} from "react-router-dom";


interface Props {
    onSuccess: () => void
}

const ResetPassword: FC<Props> = (props) => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<ApiError[]>([]);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const location = useLocation();
    const userContext = React.useContext(UserContext);
    const queryParams = new URLSearchParams(location.search);
    const getQueryParam = (param: string): string | undefined => {
        const value = queryParams.get(param);
        return value !== null ? value : undefined;
    };

    const code = getQueryParam('code');
    const email = getQueryParam('email');
    const isValid = () => {
        return code && email;
    }
    if(!isValid()){
        return (
            <>
                Niepoprawny E-mail albo Kod, wpisz jeszcze raz e-mail na jaki chcesz wygenerować link aktywacyjny.
            </>
        )
    }

    const changePassword = async () => {
        if(!email || !code) return;
        setLoading(true);
        try {
            await userContext.changePassword(email, code, password, confirmPassword);
            setLoading(false);
            props.onSuccess()
        } catch (err) {
            const exceptionErrors = handleException(err);
            setErrors(exceptionErrors);
            setLoading(false);
        }
    }
    return (
        <>
            <div>
                <h4>
                    Zmiana hasła - {email}
                </h4>
                <ErrorFormAlertView errors={errors} />
                <div className="form-group">
                    <input type="text" disabled={true} value={email} className={"form-control" + ErrorFormHelper(errors, "email")} placeholder="E-mail" aria-label="Recipient's username" aria-describedby="cart-username" />
                    <label htmlFor="cart-username">E-mail</label>
                    <ErrorFormView errors={errors} field="username" />
                </div>
                <div className="form-group">
                    <input type="password" onChange={(e) => setPassword(e.target.value)} value={password} className={"form-control" + ErrorFormHelper(errors, "password")} placeholder="Hasło" aria-label="Recipient's username" aria-describedby="cart-password" />
                    <label htmlFor="cart-password">Hasło</label>
                    <ErrorFormView errors={errors} field="password" />
                </div>
                <div className="form-group">
                    <input type="password" onChange={(e) => setConfirmPassword(e.target.value)} value={confirmPassword} className={"form-control" + ErrorFormHelper(errors, "confirm_password")} placeholder="Potwierdz hasło" aria-label="Recipient's username" aria-describedby="cart-confirm-password" />
                    <label htmlFor="cart-confirm-password">Potwierdz hasło</label>
                    <ErrorFormView errors={errors} field="confirm_password" />
                </div>
            </div>
            <button className={"btn-spinner btn-block btn-primary btn btn-submit" + (loading ? ' loading' : '')} onClick={()=>{changePassword()}}>
                <span className="left spinner-border spinner-border-sm"></span>
                Zmień hasło i zaloguj się
            </button>
        </>
    );
};

export default ResetPassword;
