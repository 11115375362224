import React, {FC, useEffect, useState} from 'react';
import {Link, RouteComponentProps, useHistory} from "react-router-dom";
import {UserContext} from "../../../../../../services/UserContext";
import {ReactComponent as UserSVG} from "../../../../../../images/svg/user/user.svg";
import {AppConfig} from "../../../../../../services/appConfig";

const UserSettingsIndexPage: FC<RouteComponentProps> = (props) => {
    let history = useHistory();
    const userContext = React.useContext(UserContext);
    let user = userContext.user;
    const [barcodeBase64, setBarcodeBase64] = useState<string | null>(null);

    useEffect(() => {
        if (!user) {
            history.push("/")
            return;
        }
        const fetchBarcode = async () => {
            const response = await userContext.getBarcode();
            if (response) {
                console.log("response", response)
                setBarcodeBase64(response);  // Set the base64 string
            }
            // const base64 = await response.text();  // Fetch the plain text Base64 string
        };

        fetchBarcode();
    }, [userContext.user]);
    const logout = async () => {
        await userContext.logout();

    }
    return (
        <div className="container">
            <div className={"user"}>
                <div className={"user-card"}>
                    <div className={"user-card-header"}>
                        <UserSVG/>
                        <h3>{user?.name}</h3>
                        {user?.points && (
                            <div className={"user-card-points"}>
                                <div className={"badge text-bg-light"}>{user?.points} pkt</div>
                            </div>
                        )}
                    </div>
                    {AppConfig.isUserComplex() && (
                        <>
                            {barcodeBase64 && (
                                <div className={"user-card-qrcode"}>
                                    <img src={`data:image/png;base64,${barcodeBase64}`} alt="QR Code"/>
                                </div>
                            )}
                        </>
                    )}
                </div>
                <div className={"user-menu"}>
                    <h3>
                        Menu
                    </h3>
                    {AppConfig.isUserComplex() && (
                        <>
                            <nav className="nav flex-column">
                                <Link to={"/user/settings/delete"} className="nav-link" aria-current="page">Usuń
                                    konto</Link>
                            </nav>
                        </>
                    )}
                    <nav className="nav flex-column">
                        <a onClick={() => logout()} className="nav-link" aria-current="page">Wyloguj się</a>
                    </nav>
                </div>
            </div>
        </div>
    )
};

export default UserSettingsIndexPage;
