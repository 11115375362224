import React, {FC} from "react";
import ControlsOrderTypeComponnent from "./components/controlsOrderType";
import {StoreFilter} from "../services/types";
import ControlsViewComponnent from "./components/controlsView";


interface Props {
    onChangeOrderType: (type: string) => void;
    onChangeViewType: (type: string) => void;
    filter: StoreFilter,
    viewType: string
}

const ControlsComponnent: FC<Props> = (props) => {

    return (
        <div className={"eater-controls"}>
            <div className={"eater-controls-order-type"}>
                <ControlsOrderTypeComponnent onChange={props.onChangeOrderType}
                                             type={props.filter?.type}/>
            </div>
            <div className={"eater-controls-view"}>
                <ControlsViewComponnent onChange={props.onChangeViewType} type={props.viewType}/>
            </div>
        </div>
    )
};

export default ControlsComponnent;
