import {FC} from 'react';
import {Helmet} from "react-helmet-async";

interface Props {
    color: string,
    css?:string
}

const CustomStyle: FC<Props> = (props) => {

    const hexToRGB = (h:any) => {
        let r = 0 as any, g = 0 as any, b = 0 as any;

        // 3 digits
        if (h.length == 4) {
            r = "0x" + h[1] + h[1];
            g = "0x" + h[2] + h[2];
            b = "0x" + h[3] + h[3];

            // 6 digits
        } else if (h.length == 7) {
            r = "0x" + h[1] + h[2];
            g = "0x" + h[3] + h[4];
            b = "0x" + h[5] + h[6];
        }

        return ""+ +r + "," + +g + "," + +b;
        // return "rgb("+ +r + "," + +g + "," + +b + ")";
    }
    const getColorCustomStyle = (color: string) => {
        if(color && (color.length == 3 || color.length == 6)){
            color = "#" + color;
        }
        let rgbColor = hexToRGB(color);
        return (
            <style>{`
.eater .search .input-group .btn-type, .eater .search .input-group .btn-type:hover {
  background-color: ${color};
}
.eater .card-profile:hover {
  border-color: ${color};
}
.eater .profile-item:hover h5, .eater .profile-item:hover .h5 {
  color: ${color};
}
.icon-go svg, .icon-go svg path {
  fill: ${color};
}
.profile-item svg, .profile-item svg path {
  fill: ${color};
}          
.eater .footer__use a:hover {
    color: ${color};
}
                    `}</style>
        );
    }


    let color = props.color;
    let css = props.css;
    let colorText = null;
    let colorBg = null;

    // if(colorBg === null && color === null){
    //     return null;
    // }

    let customColorStyle = null;
    if(color){
        customColorStyle = getColorCustomStyle(color);
    }
    let customCssStyle = null;
    if(css){
        customCssStyle = (
            <style>{`${css}`}</style>
        );
    }
    return (
        <Helmet>
            {customColorStyle}
            {customCssStyle}
            {/*{faviconView}*/}
        </Helmet>
    );
}

export default CustomStyle
// export default connect(mapStateToProps)(NavbarSearch)
