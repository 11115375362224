import React from 'react';
import {useTranslation} from "react-i18next";
import {trans} from "../../../services/Api/translation";

const ErrorFormView = (data:any)=>{
    const { t } = useTranslation();

    let errors = data.errors;
    let field = data.field;
    let fieldErrors = [] as any[];
    errors.map((error:any) => {
        if(error === undefined || !error){
            return;
        }
        let errorField = error.field;
        let code = error.code;
        let message = error.message;

        if (errorField === field) {
            fieldErrors.push({
                code: code,
                message: message
            });
        }
    });
    return(
        <React.Fragment>
            {
                fieldErrors.map((item) =>
                    <div className="invalid-feedback" key={item.code}>
                        {trans("errors." + item.code)}
                    </div>
                )
            }
        </React.Fragment>
    )
}

export default ErrorFormView;
