import React, {FC} from 'react';
import {RouteComponentProps, useHistory, useLocation} from "react-router-dom";
import ResetPassword from "../../components/User/resetPassword";

const ResetPasswordPage: FC<RouteComponentProps> = (props) => {
    let history = useHistory();
    return (
        <div className="container">
            <ResetPassword onSuccess={() => {
                history.push("/")
            }}/>
        </div>
    )
};

export default ResetPasswordPage;
