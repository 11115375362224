import React, {FC} from "react";
import {ReactComponent as MapSVG} from "../../../../../images/svg/map.svg";
import {ReactComponent as ListSVG} from "../../../../../images/svg/list.svg";


interface Props {
    onChange: (type: string) => void;
    type: string
}
const ControlsViewComponnent: FC<Props> = (props) => {

     return (
         <>
             {props.type !== "map" ? (
                 <button type="button" className={`btn btn-primary`}
                         onClick={() => props.onChange("map")}><MapSVG/>
                 </button>
             ) : (
                 <button type="button" className={`btn btn-primary`}
                         onClick={() => props.onChange("list")}><ListSVG/>
                 </button>
             )}
         </>
     )
};

export default ControlsViewComponnent;
