export function trans(message: string): string {
    let messageCode = message.replace("errors.", "")
    switch (messageCode){
        case "entity_with_name_exists":
            return "Istnieje już zasób o takiej nazwie";
        case "must_not_be_empty":
        case "must_not_be_blank":
            return "Pole nie może być puste";
        case "password_not_equal":
            return "Hasła nie są takie same";
        case "not_found":
            return "Nie znaleziono, spróbuj wpisać inne dane";
        case "must_be_true":
            return "Pole musi być zaznaczone";
        case "invalid_login":
            return "Niepoprawne dane do logowania";
        case "contact_not_exist":
            return "Kontakt o podanych danych nie istnieje";
    }
    return messageCode;
}
