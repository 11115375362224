class StorageStore {

    static items = {} as any;
    static localStorageAvailable = undefined as boolean | undefined;
    static storeDomain = "goorder-widget" as string;
    // static cookies = new Cookies();

    static setItem(key: any ,value : any){
        if (this.isLocalStorageAvailable()) {
            localStorage.setItem(this.prepareKey(key), value)
        } else {
            this.items[this.prepareKey(key)] = value;
        }
    }
    static getItem(key:any){
        if (this.isLocalStorageAvailable()) {
            return localStorage.getItem(this.prepareKey(key))
        } else {
            if(this.prepareKey(key) in this.items){
                return this.items[this.prepareKey(key)];
            }
            return null;
        }
    }
    static removeItem(key : any){
        if (this.isLocalStorageAvailable()) {
            localStorage.removeItem(this.prepareKey(key))
        } else {
            delete this.items[this.prepareKey(key)];
        }
    }

    static prepareKey(key: any){
        return this.storeDomain + "-" + key;
    }
    static isLocalStorageAvailable(){
        if(this.localStorageAvailable === undefined){
            try {
                let len = localStorage.length;
                if(typeof window.localStorage !== 'undefined'){
                    this.localStorageAvailable = true;
                }else{
                    this.localStorageAvailable = false;
                }
            } catch(e) {
                this.localStorageAvailable = false;
            }
        }
        return this.localStorageAvailable;
    }
}
export default StorageStore;