import React, {FC} from 'react';
import {RouteComponentProps, useHistory} from "react-router-dom";
import Activation from "../../components/User/activation";

const RegisterActivationPage: FC<RouteComponentProps> = (props) => {
    let history = useHistory();
    return (
        <div className="container">
            <Activation onSuccess={() => {
                history.push("/")
            }}/>
        </div>
    )
};

export default RegisterActivationPage;
