import React, {FC, useState} from "react";
import ErrorFormAlertView from "../../../../components/Common/ErrorFormAlertView";
import ErrorFormView from "../../../../components/Common/ErrorFormView";
import ErrorFormHelper from "../../../../utils/ErrorFormHelper";
import {UserContext} from "../../../../services/UserContext";
import handleException from "../../../../services/Api/handleException";
import {ApiError} from "../../../../services/Api/types";


interface Props {
    onSuccess: () => void
}

const Login: FC<Props> = (props) => {
    const [errors, setErrors] = useState<ApiError[]>([]);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const userContext = React.useContext(UserContext);
    const onShow = () => {}
    const login = async () => {
        setLoading(true);
        try {
            let response = await userContext.login(email, password)
            setLoading(false);
            props.onSuccess()
        } catch (err) {
            // const exceptionErrors = handleException(err);
            const exceptionErrors = [
                {
                    message: "invalid_login",
                    code: "invalid_login",
                },
            ]
            setErrors(exceptionErrors);
            setLoading(false);
        }
    }

    return (
        <>
            <div>
                <ErrorFormAlertView errors={errors} />
                <div className="form-group">
                    <input type="text" onChange={(e) => setEmail(e.target.value)} value={email} className={"form-control" + ErrorFormHelper(errors, "username")} placeholder="E-mail" aria-label="Recipient's username" aria-describedby="cart-username" />
                    <label htmlFor="cart-username">E-mail</label>
                    <ErrorFormView errors={errors} field="username" />
                </div>
                <div className="form-group">
                    <input type="password" onChange={(e) => setPassword(e.target.value)} value={password} className={"form-control" + ErrorFormHelper(errors, "password")} placeholder="Hasło" aria-label="Recipient's username" aria-describedby="cart-password" />
                    <label htmlFor="cart-password">Hasło</label>
                    <ErrorFormView errors={errors} field="password" />
                </div>
            </div>
            <button className={"btn-spinner btn-block btn-primary btn w-100 btn-submit" + (loading ? ' loading' : '')} onClick={()=>{login()}}>
                <span className="left spinner-border spinner-border-sm"></span>
                Zaloguj
            </button>
        </>
    )
};

export default Login;
