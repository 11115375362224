import React, {FC, useState} from "react";
import ErrorFormAlertView from "../../../../components/Common/ErrorFormAlertView";
import ErrorFormView from "../../../../components/Common/ErrorFormView";
import ErrorFormHelper from "../../../../utils/ErrorFormHelper";
import {UserContext} from "../../../../services/UserContext";
import {ApiError, ConfigApi, ConfigModuleStoreRulesApi} from "../../../../services/Api/types";
import handleException from "../../../../services/Api/handleException";


interface Props {
    onSuccess: () => void,
    config: ConfigApi
}

const Register: FC<Props> = (props) => {
    const [errors, setErrors] = useState<ApiError[]>([]);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [acceptRules, setAcceptRules] = useState(false);
    const [acceptMarketingRules, setAcceptMarketingRules] = useState(false);
    const userContext = React.useContext(UserContext);
    const onShow = () => {}
    const register = async () => {
        setLoading(true);
        try {
            let response = await userContext.register(email, name, phone, password, confirmPassword, acceptRules, acceptMarketingRules)
            setLoading(false);
            props.onSuccess()
        } catch (err) {
            const exceptionErrors = handleException(err);
            setErrors(exceptionErrors);
            setLoading(false);
        }

    }
    let rules = props.config.module_stores.rules.filter(x => x.type == "rules")[0];
    let marketingRules = props.config.module_stores.rules.filter(x => x.type == "marketing")[0];

    const renderTitleRules = (item : ConfigModuleStoreRulesApi) => {
        if(!item) return null;
        let title = item.title;
        if(item.title.includes("VARIANT_2")){
            let additionalInfos = item.title.split("|");
            let companyName;
            let addressView;
            if(additionalInfos.length > 1 && additionalInfos[1]){
                companyName = additionalInfos[1];
            }
            if(additionalInfos.length > 2 && additionalInfos[2]){
                addressView = additionalInfos[2];
            }
            title = `Wyrażam zgodę na przesyłanie informacji handlowych przez ${companyName} z siedzibą w ${addressView} za pośrednictwem komunikacji elektronicznej (SMS, e-mail). Wyrażam zgodę na przetwarzanie moich danych osobowych w celu przesyłania informacji handlowych przez T-Pizza sp. z o.o. Podstawą prawną przetwarzania danych osobowych jest wyrażona przeze mnie zgoda. Rozumiem, że przysługuje mi prawo do cofnięcia zgody w dowolnym momencie, bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem.`
        }
        return (
            <>
                {title}
                {item.url && (
                    <>(<a href={item.url}>Link</a>)</>
                )}
            </>
        )
    }

    return (
        <>
            <div>
                <h4>
                    Rejestracja
                </h4>
                <ErrorFormAlertView errors={errors} />
                <div className="form-group">
                    <input type="text" onChange={(e) => setName(e.target.value)} value={name} className={"form-control" + ErrorFormHelper(errors, "name")} placeholder="Imię i nazwisko" aria-label="Recipient's username" aria-describedby="cart-name" />
                    <label htmlFor="cart-name">Imię i nazwisko</label>
                    <ErrorFormView errors={errors} field="name" />
                </div>
                <div className="form-group">
                    <input type="text" onChange={(e) => setPhone(e.target.value)} value={phone} className={"form-control" + ErrorFormHelper(errors, "phone")} placeholder="Numer telefonu" aria-label="Recipient's username" aria-describedby="cart-phone" />
                    <label htmlFor="cart-phone">Numer telefonu</label>
                    <ErrorFormView errors={errors} field="phone" />
                </div>
                <div className="form-group">
                    <input type="text" onChange={(e) => setEmail(e.target.value)} value={email} className={"form-control" + ErrorFormHelper(errors, "email")} placeholder="E-mail" aria-label="Recipient's username" aria-describedby="cart-username" />
                    <label htmlFor="cart-username">E-mail</label>
                    <ErrorFormView errors={errors} field="username" />
                </div>
                <div className="form-group">
                    <input type="password" onChange={(e) => setPassword(e.target.value)} value={password} className={"form-control" + ErrorFormHelper(errors, "password")} placeholder="Hasło" aria-label="Recipient's username" aria-describedby="cart-password" />
                    <label htmlFor="cart-password">Hasło</label>
                    <ErrorFormView errors={errors} field="password" />
                </div>
                <div className="form-group">
                    <input type="password" onChange={(e) => setConfirmPassword(e.target.value)} value={confirmPassword} className={"form-control" + ErrorFormHelper(errors, "confirm_password")} placeholder="Potwierdz hasło" aria-label="Recipient's username" aria-describedby="cart-confirm-password" />
                    <label htmlFor="cart-confirm-password">Potwierdz hasło</label>
                    <ErrorFormView errors={errors} field="confirm_password" />
                </div>
                {rules && (
                    <div className="form-group">
                        <div className={"form-check"}>
                            <input id="cart-accept_rules" name={"accept_rules"} type="checkbox" onChange={(e) => setAcceptRules(!acceptRules)} value={1} checked={acceptRules} className={"form-check-input" + ErrorFormHelper(errors, "accept_rules")} aria-label="Akceptacja regulaminu" aria-describedby="cart-accept_rules" />
                            <label className={"form-check-label"} htmlFor="cart-accept_rules">{renderTitleRules(rules)}</label>
                            <ErrorFormView errors={errors} field="accept_rules" />
                        </div>
                    </div>
                )}
                {marketingRules && (
                    <div className="form-group">
                        <div className={"form-check"}>
                            <input id="cart-accept_marketing_rules" name="accept_marketing_rules" type="checkbox" onChange={(e) => setAcceptMarketingRules(!acceptMarketingRules)} value={1} checked={acceptMarketingRules} className={"form-check-input" + ErrorFormHelper(errors, "accept_marketing_rules")} aria-label="Akceptacja regulaminu marketingowego" aria-describedby="cart-accept_marketing_rules" />
                            <label className={"form-check-label"} htmlFor="cart-accept_marketing_rules">{renderTitleRules(marketingRules)}</label>
                            <ErrorFormView errors={errors} field="accept_marketing_rules" />
                        </div>
                    </div>
                )}
            </div>
            <button className={"btn-spinner btn-block btn-primary btn btn-submit" + (loading ? ' loading' : '')} onClick={()=>{register()}}>
                <span className="left spinner-border spinner-border-sm"></span>
                Założ konto
            </button>
        </>
    )
};

export default Register;
