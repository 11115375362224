import React, {FC, useState} from "react";
import {Modal} from "react-bootstrap";
import ErrorFormAlertView from "../../../../components/Common/ErrorFormAlertView";
import ErrorFormView from "../../../../components/Common/ErrorFormView";
import ErrorFormHelper from "../../../../utils/ErrorFormHelper";
import {UserContext} from "../../../../services/UserContext";
import Login from "./login";
import Register from "./register";
import {ConfigApi, ConfigModuleStoreApi} from "../../../../services/Api/types";
import LostPassword from "./lostPassword";


interface Props {
    show: boolean;
    config: ConfigApi;
    onHide: () => void
}

const LoginModal: FC<Props> = (props) => {
    const userContext = React.useContext(UserContext);
    const [page, setPage] = useState("LOGIN");

    const onShow = () => {}
    const showRegister = () => {

    }


    return (
        <>
            <Modal dialogClassName="modal-full modal-login" show={props.show} onHide={props.onHide} onShow={onShow}>
                <div className="">
                    <Modal.Header>
                        {page == "LOGIN" && (
                            <>
                                {/*<Modal.Title>*/}
                                {/*    Logowanie*/}
                                {/*</Modal.Title>*/}
                            </>
                        )}
                        {page == "REGISTER" && (
                            <button className={"btn btn-transparent"} onClick={() => setPage("LOGIN")}>
                                Wróć
                            </button>
                        )}
                        {page == "LOST_PASSWORD" && (
                            <button className={"btn btn-transparent"} onClick={() => setPage("LOGIN")}>
                                Wróć
                            </button>
                        )}
                        <button onClick={props.onHide} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </Modal.Header>
                </div>
                <Modal.Body>
                    <div className={"login-box"}>
                        {props.config.logo ? (
                            <div className={"login-box-logo"}>
                                <img src={props.config.logo} />
                            </div>
                        ) : "Logo"}
                        {page == "LOGIN" && (
                            <>
                                <Login onSuccess={() => props.onHide()} />
                                <a className={"login-box-lost-password"} href={"#"} onClick={() => setPage("LOST_PASSWORD")}>Zapomniałeś hasła?</a>
                                <div className="horizontal-row"><div className="horizontal-text">lub</div></div>
                                <button className={"btn btn-secondary"} onClick={() => setPage("REGISTER")}>
                                    Załóż konto
                                </button>
                            </>
                        )}
                        {page == "REGISTER" && (
                            <>
                                <Register config={props.config} onSuccess={() => props.onHide()} />
                            </>
                        )}
                        {page == "LOST_PASSWORD" && (
                            <>
                                <LostPassword onSuccess={() => setPage("LOGIN")} />
                            </>
                        )}
                    </div>

                </Modal.Body>
                {page == "LOGIN" && (
                    <Modal.Footer>
                        <>
                            <button className={"btn btn-default w-100"} onClick={() => props.onHide()}>
                                Uzywaj bez logowania
                            </button>
                        </>
                    </Modal.Footer>
                )}
            </Modal>
        </>
    )
};

export default LoginModal;
