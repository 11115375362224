import React, {FC} from "react";


interface Props {
    onChange: (type: string) => void;
    type?: string
}
const ControlsOrderTypeComponnent: FC<Props> = (props) => {

     return (
         <>
             <div className="btn-group" role="group" aria-label="Order type toggle">
                 <button type="button" className={`btn btn-outline-primary${props.type == "DELIVERY" ? " active" : ""}`}
                         onClick={() => props.onChange("DELIVERY")}>
                     Dostawa
                 </button>
                 <button type="button" className={`btn btn-outline-primary${props.type == "PICK_UP" ? " active" : ""}`}
                         onClick={() => props.onChange("PICK_UP")}>
                     Na miejscu
                 </button>
             </div>
         </>
     )
};

export default ControlsOrderTypeComponnent;
