
let isIframe = false;
if (window.top != window.self)
{
    isIframe = true;
}
class IframeUtils {

    static redirectFull(url:string){
        if(isIframe){
            let message = {type: 'OPEN_FULL', url: url};
            window.parent.postMessage(message,"*");
        }else{
            window.location.href = url;
        }
    }
    static redirectInside(url:string){
        if(isIframe){
            let message = {type: 'OPEN_INSIDE', url: url};
            window.parent.postMessage(message,"*");
        }else{
            window.location.href = url;
        }
    }

    static redirect(url:string){
        if(isIframe){
            let message = {type: 'RELOAD_PARENT', url: url};
            window.parent.postMessage(message,"*");
        }else{
            window.location.href = url;
        }
    }
}
export default IframeUtils;
