import React, {FC} from "react";
import {Coordinates, ProfileApi} from "../../../../services/Api/types";
import EaterItem from "../Item";
import {StoreFilter} from "../services/types";
import ControlsViewComponnent from "../Controls/components/controlsView";
import ControlsOrderTypeComponnent from "../Controls/components/controlsOrderType";

interface Props {
    profiles: ProfileApi[] | undefined,
    openStore: (profile: ProfileApi) => void,
    showStore: (domain: string | undefined) => void,
    coordinates: Coordinates | undefined,
    filter: StoreFilter | undefined,
    onChangeOrderType: (type: string) => void,
    onChangeViewType: (type: string) => void,
    viewType: string
}

const EaterList: FC<Props> = (props) => {
    if (props.profiles === undefined) {
        return null;
    }
    let showProfile = (domain: string | undefined) => {
        props.showStore(domain);
    }

    let sortedProfiles = props.profiles;

    return (
        <>
            <div className="eater-list">
                <div className={"eater-list-profiles"}>
                    {props.profiles.length <= 0 ?
                        (
                            <div className={"text-center"}>
                                Nie znaleziono żadnej lokalizacji dostępnej w wybranym adresie.
                            </div>
                        ) : (
                            <>
                                {sortedProfiles.map(profile => {
                                    return (
                                        <div key={profile.domain} className={"card-profile"}
                                             onMouseEnter={() => showProfile(profile.domain)}
                                             onMouseLeave={() => showProfile(undefined)}>
                                            <EaterItem openStore={props.openStore} profile={profile}
                                                       showUnavailable={props.filter?.type === undefined || props.filter?.type == "DELIVERY"}/>
                                        </div>
                                    )
                                })}
                            </>
                        )}
                </div>
                <div className={"eater-controls eater-controls-fixed"}>
                    <div className={"eater-controls-order-type"}>
                        <ControlsOrderTypeComponnent onChange={props.onChangeOrderType} type={props.filter?.type}/>
                    </div>
                    <div className={"eater-controls-view"}>
                        <ControlsViewComponnent onChange={props.onChangeViewType} type={props.viewType}/>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EaterList;
