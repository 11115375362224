import {parse, stringify} from "query-string";

const parseValue = (value: any): any => {
  if (Array.isArray(value)) {
    return value.map((v) => parseValue(v));
  }

  switch (value) {
  case "true":
    return true;
  case "false":
    return false;
  case "null":
    return null;
  }

  if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/.test(value)) {
    return value;
  }

  if (/^-?\d{1,6}$/.test(value)) {
    return parseInt(value);
  }

  return value;
};

const stringifyValue = (value: any) => {
  if (value instanceof Date) {
    return value;
  }

  return value;
};

export function parseSearchParams(s = document.location.search) {
  const parsed = parse(s.replace(/(\w+)\[\d+\]/g, "$1[]"), {arrayFormat: "bracket"});

  return Object.keys(parsed).reduce((object, key) => {
    const value = parseValue(parsed[key]);

    return {...object, [key]: value};
  }, {});
}

export function stringifySearchParams(params: Record<string, any>) {
  const object = Object.keys(params).reduce((object, key): any => {
    const value = params[key];
    if (null == value) {
      return object;
    }
    return {...object, [key]: stringifyValue(value)};
  }, {});
  return stringify(object, {arrayFormat: "index"});
}

export const delay = async (ms: number) =>
{
  return new Promise(resolve => setTimeout(resolve, ms));
}


export const wrapPromise = (promise : Promise<any>) => {
  let status = "pending";
  let result:any = undefined;
  let suspender = promise.then(
      r => {
        status = "success";
        result = r;
      },
      e => {
        status = "error";
        result = e;
      }
  );
  return {
    read() {
      if (status === "pending") {
        throw suspender;
      } else if (status === "error") {
        throw result;
      } else if (status === "success") {
        return result;
      }
    }
  };
}