import { AxiosInstance } from "axios";
import { handleResponse } from "./handleResponse";

export default (axiosClient: AxiosInstance, customOptions = {}) => {
	axiosClient.interceptors.response.use((response) => {
		return handleResponse(response);
	},
	(error) => {
		const originalRequest = error.config;
		if (originalRequest === undefined) {
			return Promise.reject(error);
		}
		return handleResponse(error.response);
	});
};
