import React, {FC, useState} from "react";
import ErrorFormAlertView from "../../../../components/Common/ErrorFormAlertView";
import ErrorFormView from "../../../../components/Common/ErrorFormView";
import ErrorFormHelper from "../../../../utils/ErrorFormHelper";
import {UserContext} from "../../../../services/UserContext";
import handleException from "../../../../services/Api/handleException";
import {ApiError} from "../../../../services/Api/types";


interface Props {
    onSuccess: () => void
}

const LostPassword: FC<Props> = (props) => {
    const [errors, setErrors] = useState<ApiError[]>([]);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [success, setSuccess] = useState(false);
    const userContext = React.useContext(UserContext);
    const resetPassword = async () => {
        setLoading(true);
        try {
            let response = await userContext.resetPassword(email)
            setLoading(false);
            setSuccess(true);
            // props.onSuccess()
        } catch (err) {
            const exceptionErrors = handleException(err);
            setErrors(exceptionErrors);
            setLoading(false);
        }
    }
    if(success){
        return (
            <>
                <div>
                    <h4>
                        Przypomnij hasło
                    </h4>
                    <p>
                        Dziękujemy za zgłoszenie prośby o zresetowanie hasła. Link do zresetowania hasła został wysłany na adres e-mail (<strong>{email}</strong>) powiązany z Twoim kontem. Sprawdź swoją skrzynkę e-mailową, a następnie postępuj zgodnie z instrukcjami zawartymi w wiadomości, aby zresetować hasło.
                   </p>
                    <p>
                        Jeśli nie otrzymałeś e-maila w ciągu kilku minut, sprawdź folder ze spamem lub skontaktuj się z naszym działem obsługi klienta.
                    </p>
                </div>
                <button className={"btn-spinner btn-block btn-primary btn w-100 btn-submit"} onClick={()=>{props.onSuccess()}}>
                    Zaloguj się
                </button>
            </>
        )
    }

    return (
        <>
            <div>
                <h4>
                    Przypomnij hasło
                </h4>
                <ErrorFormAlertView errors={errors} />
                <div className="form-group">
                    <input type="text" onChange={(e) => setEmail(e.target.value)} value={email} className={"form-control" + ErrorFormHelper(errors, "email")} placeholder="E-mail" aria-label="Recipient's username" aria-describedby="cart-username" />
                    <label htmlFor="cart-username">E-mail</label>
                    <ErrorFormView errors={errors} field="email" />
                </div>
            </div>
            <button className={"btn-spinner btn-block btn-primary btn w-100 btn-submit" + (loading ? ' loading' : '')} onClick={()=>{resetPassword()}}>
                <span className="left spinner-border spinner-border-sm"></span>
                Resetuj hasło
            </button>
        </>
    )
};

export default LostPassword;
