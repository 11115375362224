import React, {ComponentType, FC, useState} from "react";
import MyAsyncTypeahead from "../myAsyncTypeahead";
import {TypeaheadComponentProps} from "react-bootstrap-typeahead/types/components/Typeahead";
import {Menu, MenuItem} from "react-bootstrap-typeahead";
import {InputGroup} from "react-bootstrap";
import {Loading} from "../../../../../components/Common/Loading/Loading";
import {Option, TypeaheadManagerChildProps} from "react-bootstrap-typeahead/types/types";
import Typeahead2 from "react-bootstrap-typeahead/types/core/Typeahead";


declare module "react-bootstrap-typeahead" {
    function withAsync<T extends UseAsyncProps = UseAsyncProps>(Component: ComponentType<T>): React.ForwardRefExoticComponent<React.PropsWithoutRef<T> &  React.RefAttributes<Typeahead2>>;
    interface UseAsyncProps extends TypeaheadComponentProps {
        renderMenu?: (results: Option[], menuProps: any, state: TypeaheadManagerChildProps) => JSX.Element;
        className?: any,
        placeholder?: any
    }
}
interface Props {
    onChange: (value:any) => void,
    onBlur: (value:any) => void,
    value: any,
    placeholder: string,
    fetch: (query: string) => Promise<any[]>
}
const parseObjOrString = (obj : any) => {
    if(!obj || typeof obj === 'string'){
        return {
            "description": obj !== undefined ? obj : ""
        }
    }
    return obj;
}
const BaseSearchComponent: FC<Props> = (props) => {
    let options = {} as TypeaheadComponentProps
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState<any[]>([]);
    const [selected, setSelected] = useState<any[]>([
        parseObjOrString(props.value)
    ]);
    let _handleSearch = async (query:string) => {
        props.onChange({
            description: query
        });
        if(query.length <=0){
            return;
        }
        setLoading(true);

        let response = await props.fetch(query);
        setItems(response);
        setLoading(false);
    }
    let searchOnBlur = (e: any) => {
        let value = e.target.value;
        let selectedItem = selected !== undefined && selected.length > 0 && selected[0] ? selected[0] : {} as any;
        selectedItem.description = value;
        props.onChange(selectedItem)
        props.onBlur(selectedItem);
    }
    let handleSearchOnChange= async (newSelected:any) => {
        if(newSelected === undefined || newSelected.length <= 0){
            let selectedItem = newSelected[0];
            props.onChange({description:""});
            setSelected([])
            return;
        }
        let selectedItem = newSelected[0];
        props.onChange(selectedItem);
        setSelected([selectedItem])
    }
    let _renderMenu = (results:any, menuProps:any, state:any) => {
        delete menuProps.newSelectionPrefix;
        delete menuProps.paginationText;
        delete menuProps.renderMenuItemChildren;
        const items = results.map((option:any, index:any) => (
            <MenuItem  key={index} option={option} position={index}>
                {option.renderText ? (
                    <>
                        {option.renderText()}
                    </>
                ) : (
                    <>
                        {option.description}
                    </>
                )}
            </MenuItem>
        ));
        return <Menu {...menuProps}>
            {items}
            {/*<div className="dropdown-divider"></div>*/}
            {/*<div className="dropdown-divider"></div>*/}
        </Menu>;
    }
    let handleSearchValue= (value : any) => {
        if(!value){
            props.onChange({description: ""})
            return;
        }
        if(selected[0] !== undefined){
            selected[0] = {description: value}
            setSelected(selected)
        }
    };
    return (
        <div>
            <InputGroup>
                <MyAsyncTypeahead
                    {...options}
                    id="typehead"
                    isLoading={loading}
                    onSearch={_handleSearch}
                    delay={500}
                    minLength={0}
                    useCache={false}
                    onBlur={searchOnBlur}
                    filterBy={(option:any, props:any) => {
                        return true;
                    }}
                    options={items}
                    labelKey={(option:any) => {
                        return option.description;
                    }}
                    selected={selected}
                    onChange={handleSearchOnChange}
                    renderMenu={_renderMenu}
                    className={"search-input"}
                    placeholder={props.placeholder}
                    clearButton={true}
                    onInputChange={handleSearchValue}
                    searchText={<>
                        <Loading>Trwa wyszukiwanie</Loading>
                    </>}
                    promptText={"Wpisz aby wyszukać"}
                    emptyLabel={"Nie znaleziono wyniku, możesz wpisać wartość ręcznie."}
                />
            </InputGroup>
        </div>

   );
}
export default BaseSearchComponent;
